import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { constructLink, formColor } from '../../../helper';

import styles from './styles.module.css';

class ButtonEditor extends React.Component {

  static fontSizeSwitch(size) {

    switch (size) {

      case 'Small':
        return '0.85rem';

      case 'Normal':
        return '1rem';

      case 'Large':
        return '1.25rem';

      default:
        return '1rem';

    }

  }

  constructor(props) {

    super(props);

    let link;
    let type;
    if (props.data) {

      ({
        link, type,
      } = constructLink(props.data.linkObj, props.pagePathList,
        props.articlePathList, props.filePathList));

    }

    let label;
    if (props.text !== undefined) label = props.text;
    else if (props.data.label) ({ label } = props.data);
    else label = '';

    const { data } = props;
    if (data && data.styles && data.styles.id && props.buttons) {

      props.buttons.some(b => {

        let found = false;
        if (data.styles.id === b.id) {

          data.styles = b;
          found = true;

        }

        return found;

      });

    }

    this.state = {
      type,
      link,
      data,
      label,
    };

  }

  render() {

    let bColor;
    let border;
    let borderRadius = `${this.props.themeData.button.radius}px`;
    let boxShadow;
    let fontColor;
    let bgColor;

    if (this.state.data && this.state.data.styles) {

      let bColorHex = this.props.themeData.colors[2];
      const defaultThickness = this.props.button.includes('Secondary') ? '1px' : 0;
      let bThickness = defaultThickness;

      if (this.state.data.styles.border && this.state.data.styles.border.active) {

        if (this.state.data.styles.border.color !== '') bColorHex = this.state.data.styles.border.color;
        bThickness = `${this.state.data.styles.border.thickness}px`;
        borderRadius = `${this.state.data.styles.border.radius}px`;

      }

      bColor = formColor(
        { solid: bColorHex },
        false,
        this.state.data.styles.border ? this.state.data.styles.border.opacity : 1,
        undefined,
        this.props.themeData.colors,
        this.props.invert,
      );

      border = `${bColor.backgroundColor} solid ${bThickness}`;

      if (this.state.data.styles.shadow && this.state.data.styles.shadow.active) {

        const {
          x, y, blur, spread,
        } = this.state.data.styles.shadow;

        let shadow = this.state.data.styles.shadow.color;
        if (this.state.data.styles.shadow.color === '') {

          [, , shadow] = this.props.themeData.colors;

        }

        const sColor = formColor(
          { solid: shadow },
          false,
          this.state.data.styles.shadow ? this.state.data.styles.shadow.opacity : 1,
          undefined,
          this.props.themeData.colors,
          this.props.invert,
        );

        boxShadow = `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}`;

      }

      let font = this.props.button.includes('Secondary') ? this.props.themeData.colors[0] : '#FFF';
      if (
        this.state.data.styles.fontColor
        && this.state.data.styles.fontColor.color
      ) font = this.state.data.styles.fontColor.color;

      fontColor = formColor(
        { solid: font },
        undefined,
        this.state.data.styles.fontColor ? this.state.data.styles.fontColor.opacity : 1,
        undefined,
        this.props.themeData.colors,
        this.props.invert,
      );

      let backgroundColor = this.props.button.includes('Secondary') ? { solid: '#FFF' } : { solid: this.props.themeData.colors[0] };
      if (
        this.state.data.styles.backgroundColor
        && (
          this.state.data.styles.backgroundColor.solid
          || this.state.data.styles.backgroundColor.gradient.from
        )
      ) ({ backgroundColor } = this.state.data.styles);

      bgColor = formColor(
        backgroundColor,
        false,
        this.state.data.styles.backgroundColor ? this.state.data.styles.backgroundColor.opacity : 1,
        undefined,
        this.props.themeData.colors,
        this.props.invert,
      );

    }

    if (this.props.fullWidth) borderRadius = '';
    const fontFamily = this.props.themeData.typography.button.name;
    const fontSize = ButtonEditor.fontSizeSwitch(this.props.themeData.typography.button.fontSize);
    const { textTransform } = this.props.themeData.typography.button;
    const fontWeight = this.props.themeData.typography.button.weight;

    const style = {
      borderRadius,
      border,
      boxShadow,
      fontFamily,
      fontSize,
      textTransform,
      fontWeight,
      color: fontColor ? fontColor.backgroundColor : undefined,
    };

    if (bgColor) {

      if (bgColor.background) style.background = bgColor.background;
      else style.backgroundColor = bgColor.backgroundColor;

    }

    let icon;
    if (this.state.data && this.state.data.icon && this.state.data.icon.active === true) {

      style.paddingLeft = '14px';
      let color = this.props.data.icon.color || '#000000';
      if (color) {

        color = formColor(
          { solid: color },
          false,
          1,
          undefined,
          this.props.themeData.colors,
          this.props.invert,
        ).backgroundColor;

      }

      icon = (
        <span
          className={`entypo ${this.state.data.icon.class}`}
          aria-hidden
          style={{
            marginRight: this.state.label ? '16px' : '0',
            fontSize: this.state.data.icon.size,
            color,
          }}
        />
      );

    }

    const button = (
      <button
        className={`${styles.Button}${this.props.fullWidth === true ? ` ${styles.full}` : ''}${this.props.styleN ? ` ${styles[this.props.styleN]}` : ''}`}
        style={style}
        type={this.props.type || 'submit'}
      >
        { icon }
        {HTMLParser(this.state.label)}
      </button>
    );

    const isLink = this.state.link !== undefined;
    const isLocal = isLink && this.state.type !== 'EXTERNAL' && this.state.type !== 'FILE';
    let previewTarget;
    if (!isLocal) previewTarget = this.state.link && this.state.link.startsWith('http') ? '_blank' : '_self';
    else {

      if (typeof this.state.data.linkObj === 'object') previewTarget = this.state.data.linkObj.openLinkInNewTab ? '_blank' : '_self';
      else previewTarget = this.state.data.tab ? '_blank' : '_self';

    }

    let result = button;
    if (isLink && isLocal && previewTarget !== '_blank') {

      result = (
        <Link
          to={this.state.link}
          className={this.props.fullWidth === true ? `${styles.width100}` : ''}
        >
          { button }
        </Link>
      );

    } else if (isLink && (!isLocal || previewTarget === '_blank')) {

      result = (
        <a
          href={this.state.link}
          target={previewTarget}
          rel="noopener noreferrer"
          className={this.props.fullWidth === true ? `${styles.width100}` : ''}
        >
          { button }
        </a>
      )

    }

    return result;

  }

}

ButtonEditor.propTypes = {
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  data: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    styles: PropTypes.shape({
      backgroundColor: PropTypes.shape({
        opacity: PropTypes.number,
        solid: PropTypes.string,
        gradient: PropTypes.shape({
          from: PropTypes.string,
          to: PropTypes.string,
        }),
      }),
      fontColor: PropTypes.shape({
        color: PropTypes.string,
        opacity: PropTypes.number,
      }),
      radius: PropTypes.number,
      shadow: PropTypes.shape({
        active: PropTypes.bool,
        color: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        blur: PropTypes.number,
        spread: PropTypes.number,
        opacity: PropTypes.number,
      }),
      border: PropTypes.shape({
        active: PropTypes.bool,
        color: PropTypes.string,
        thickness: PropTypes.number,
        opacity: PropTypes.number,
        radius: PropTypes.number,
      }),
    }),
  }),
  button: PropTypes.string,
};

export default ButtonEditor;
