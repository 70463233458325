import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import { Link } from 'gatsby';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import ButtonEditor from '../../ButtonEditor';
import Image from '../../../Image';
import Logo from '../../../../images/oidom-logo.svg';

import styles from './styles.module.css';

class FooterLayout extends React.Component {

  constructor(props) {

    super(props);

    const full = props.section.styles.full === true ? 'Full' : '';
    let columns;
    if (props.section && props.section.styles
      && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      section: props.section,
      layout: props.layout,
      full,
      columns,
      nav: props.footerLinks,
    };

    this.createLink = this.createLink.bind(this);

  }

  createLink(l, index, subindex, main) {

    const rawLink = l;
    let link;
    let targetValue;

    if (rawLink.type === 'EXTERNAL') targetValue = rawLink.openLinkInNewTab ? '_blank' : '_self';
    else if (rawLink.type === 'FILE') targetValue = '_blank';

    let button;
    if (rawLink.format === 'BUTTON') {

      button = (
        <ButtonEditor
          themeData={this.props.themeData}
          button="Button"
          data={rawLink.formatData}
          text={rawLink.text || rawLink.name || rawLink.path || ''}
        />
      );

    }

    let linkContent = (
      <React.Fragment>
        {
          rawLink.format === 'IMAGE' && rawLink.formatData && rawLink.formatData.id
          && (
            (
              <Image
                id={rawLink.formatData.id}
                alt={`Footer_Image_${rawLink.formatData.id}`}
                imageClass="navbarImage"
                imageStyle="navbarImage"
                images={this.props.images}
              />
            )
          )
        }
        { button }
        {
          rawLink.format === 'ICON' && rawLink.formatData
          && (
            <i className={`entypo ${rawLink.formatData.class}`} style={{ fontSize: rawLink.formatData.size, color: rawLink.formatData.color }} aria-hidden />
          )
        }
        {
          rawLink.format === 'TEXT'
          && (
            (rawLink.type === 'EXTERNAL' && (rawLink.text || rawLink.path))
            || ((rawLink.type === 'ARTICLE' || rawLink.type === 'FILE') && (rawLink.text || rawLink.name))
            || rawLink.name
          )
        }
      </React.Fragment>
    );

    if (main && l.format === 'TEXT') linkContent = <h3 key={`Footer_nav_link_${index}`} className={`footerHeading ${styles.footerHeader}`}>{linkContent}</h3>;
    else if (l.format === 'TEXT') linkContent = <p key={`Footer_nav_link_${index}${subindex}`} className={styles.link}>{linkContent}</p>;

    if (rawLink.type === 'EXTERNAL' || rawLink.type === 'FILE') {

      link = (
        <a
          href={rawLink.path}
          target={targetValue}
          rel="noopener noreferrer"
        >
          { linkContent }
        </a>
      );

    } else {

      let url = rawLink.path || '/';
      if (rawLink.type === 'SECTION') url = `${rawLink.path}#${rawLink.id}`;
      link = (
        <Link
          to={url}
          state={rawLink.type === 'SECTION' ? { scrollToSection: rawLink.id } : undefined}
        >
          { linkContent }
        </Link>
      );

    }

    let result = link;
    if (rawLink.format !== 'TEXT') result = <div key={`Footer_nav_link_${index}${subindex}`} className={styles.linkWrapper}>{link}</div>;

    return result;

  }

  render() {

    const activeComponents = [];
    const components = [];
    this.state.section.data.map((item, index) => {

      if (item.type.startsWith('COMPONENT') && item.active) {

        activeComponents.push({ item, index });

      }

      return null;

    });

    activeComponents.map((component, componentIndex) => {

      let bg;
      if (component.item && component.item.styles && component.item.styles.backgroundColor.active) {

        let { backgroundColor } = component.item.styles;
        if (
          component.item.styles.backgroundColor.solid === ''
          && component.item.styles.backgroundColor.gradient.from === ''
        ) {

          backgroundColor = { solid: '#ffffff' };

        }

        bg = formColor(
          backgroundColor,
          false,
          component.item.styles.backgroundColor.opacity,
          undefined,
          this.props.themeData.colors,
        );

      }

      components.push({
        label: component.item.label,
        index: component.index,
        elements: [],
        style: bg,
        type: component.item.type,
      });

      component.item.data.map((element, elementIndex) => {

        let item;
        let type;
        const align = styles[`align${component.item.type === 'COMPONENT/PERMANENT' && componentIndex ? 'Disc' : ''}${component.item.align}`];

        if (element.active && element.type === 'IMAGES/IMAGE') {

          item = (
            <SectionMedia
              key={componentIndex === 0 ? `${this.state.section._id}_layout_${elementIndex}_upper` : ''}
              mediaType={(this.state.section.data[component.index].data[elementIndex].content.icon) ? 'ICON' : 'IMAGE'}
              wrapperStyle="footerImage"
              elementStyle="footerImage"
              iconStyle=""
              sizes="16vw"
              align={component.item.align}
              src={this.state.section.data[component.index].data[elementIndex].content.src}
              alt={this.state.section.data[component.index].data[elementIndex].content.alt}
              data={this.state.section.data[component.index].data[elementIndex].content}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              colors={this.props.themeData.colors}
            />);

        } else if (element.active && element.type === 'PARAGRAPH/PARAGRAPH') {

          type = 'paragraph';
          item = (<span>{HTMLParser(element.text)}</span>);

        } else if (element.active && element.type === 'HEADINGS/HEADING-THREE') {

          type = 'header';
          item = (<h3 className="footerHeading">{HTMLParser(element.text)}</h3>);

        }

        if (item) {

          if (element.type === 'IMAGES/IMAGE') {

            components[componentIndex].elements.push(item);

          } else {

            const editor = (
              <div
                key={componentIndex === 0 ? `${this.state.section._id}_layout_${elementIndex}_upper` : ''}
                className={`${type === 'header' ? styles.footerHeader : ''} ${styles.footerTextWrapper} ${align}`}
              >
                {item}
              </div>
            );

            components[componentIndex].elements.push(editor);

          }

        }

        return null;

      });

      return null;

    });

    const { active } = this.state.section.data[0];
    const left = [];
    const right = [];
    const disclaimer = [];

    components.map(component => {

      if (active && component.index === 0) {

        left.push(component);

      } else if (component.index > 0 && component.type === 'COMPONENT') {

        right.push(component);

      } else if (component.index > 0 && component.type === 'COMPONENT/PERMANENT') {

        disclaimer.push(component);

      }

      return null;

    });

    let className;
    const len = right.length + 1 + (this.state.nav ? this.state.nav.length : 0);
    if (this.state.columns) {

      className = `${styles.colWrapper} ${this.state.columns === 1 ? 'col-12' : `col-12 col-sm-6 col-md-${12 / this.state.columns}`}`;

    } else if (len === 1) {

      className = `${styles.colWrapper} col-12`;

    } else if (len === 2) {

      className = `${styles.colWrapper} col-12${this.state.layout === 'firstWide' ? '' : ' col-sm-6'}`;

    } else if (len === 3) {

      className = `${styles.colWrapper} col-12 col-sm-6${this.state.layout === 'firstWide' ? '' : ' col-md-4'}`;

    } else {

      className = `${styles.colWrapper} col-12 col-sm-6 col-md-4${this.state.layout === 'firstWide' ? '' : ' col-lg-3'}`;

    }

    let line;
    if (this.state.layout === 'firstWide') {

      line = <div className={`col-12 ${styles.line}`} />;

    }

    let disc;
    if (disclaimer.length > 0 || this.props.badge) {

      const texts = [];
      if (disclaimer.length > 0) {

        disclaimer.forEach(component => {

          const text = (
            <div
              className={`col-12${disclaimer.length > 1 ? ' col-sm-6' : ''}`}
              key={`${this.state.section._id}_Component_${component.index}_section`}
              style={component.style}
            >
              {
                component.elements.map((element, index) => (
                  <div
                    key={`${this.state.section._id}_layout_${index}_lower`}
                    className={index === 0
                      ? styles.footerDisclaimerRight
                      : styles.footerDisclaimerLeft
                    }
                  >
                    { element }
                  </div>
                ))
              }
            </div>
          );
          texts.push(text);

        });

      }

      let badge;
      if (this.props.badge && this.props.badge.active) {

        badge = (
          <a href={`https://oidom.${this.props.language === 'fi' ? 'fi' : 'com'}`} target="_blank" rel="noopener noreferrer">
            <div className={`${styles.badgeContainer}${disclaimer.length === 0 ? ` ${styles.padding}` : ''}`}>
              <span
                style={{
                  color: this.props.badge.colour === 'dark' ? '#000' : '#fff',
                }}
              >
                <p>{this.props.badge.text}</p>
              </span>
              <img src={Logo} alt="oidom badge" className={styles.badge} />
            </div>
          </a>
        );

      }

      let content;
      if (
        (disclaimer.length) > 0
        && (!this.props.badge || (this.props.badge && !this.props.badge.active))
      ) content = texts;
      else if (disclaimer.length === 0 && this.props.badge
        && this.props.badge.active) content = badge;
      else {

        content = (
          <div className={`row ${styles.badgeWrapper}`}>
            <div className={`col-12 col-sm-9 ${styles.badgeDisclaimer}`}>
              { texts }
            </div>
            <div className="col-12 col-sm-3">
              { badge }
            </div>
          </div>
        );

      }

      disc = (
        <div className={`row ${styles.footerDisclaimerWrapper}${disclaimer.length === 0 ? ` ${styles.end}` : ''}`}>
          { line }
          { content }
        </div>
      );

    }

    const rightContent = [];
    if (right.length > 0) {

      right.forEach((component, index) => {

        const content = (
          <div
            key={`${this.state.section._id}_layout_${index}`}
            className={`${this.state.layout === 'firstWide' && component.index === 1 ? 'col-12' : className}${this.state.layout === 'firstWide' && component.index === 1 ? ` ${styles.wide}` : ''}`}
            style={component.style}
          >
            {
              component.elements.map((element, index2) => (
                <React.Fragment key={`${this.state.section._id}_layout_${index}_${index2}`}>
                  { element }
                </React.Fragment>
              ))
            }
          </div>
        );
        rightContent.push(content);

      });

    }

    const navContent = [];
    if (this.state.nav && this.state.nav && this.state.nav.length > 0) {

      this.state.nav.forEach((n, index) => {

        const result = [];

        result.push(this.createLink(n, index, undefined, true));
        if (n.children && n.children.length > 0
        ) n.children.forEach((c, i) => result.push(this.createLink(c, index, i)));

        const content = (
          <div
            key={`${this.props.section._id}_layout_nav_${index}`}
            className={`${this.props.layout === 'firstWide' && index === 0 && rightContent.length === 0 ? 'col-12' : className}${this.props.layout === 'firstWide' && index === 0 && rightContent.length === 0 ? ` ${styles.wide}` : ''}`}
          >
            { result }
          </div>
        );
        navContent.push(content);

      });

    }

    return (
      <div className={`container ${styles[`footerContainer${this.state.full}`]}`}>
        <div className={`row ${styles[this.state.layout]}`}>
          {
            active
            && (
              <div className="col-12 col-md-3">
                <div
                  className={`${styles.social} ${styles[`align${this.state.section.data[0].align}`]}`}
                  style={left[0].style}
                >
                  { left[0].elements }
                </div>
              </div>
            )
          }
          {
            <div className={!active ? 'col-12' : 'col-12 col-md-9'}>
              <div className="row">
                { rightContent }
                { navContent }
              </div>
            </div>
          }
        </div>
        { disc }
      </div>
    );

  }

}

FooterLayout.propTypes = {
  layout: PropTypes.string,
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
      full: PropTypes.bool,
    })),
  }),
  siteLanguage: PropTypes.string,
  badge: PropTypes.shape({
    text: PropTypes.string,
    colour: PropTypes.string,
  }),
};

export default FooterLayout;
