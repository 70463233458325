import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../helper';
import NavigationLinks from './NavigationLinks';
import Image from '../Image';
import styles from './styles.module.css';

class NavigationBar extends React.Component {

  constructor(props) {

    super(props);

    const { logo, sitename, themeData } = props.pageContext;
    const section = props.pageContext.navigation;
    const { header } = props.pageContext.page;

    const burgerBreak = Number(section.styles.burger) || 5;
    const divider = section.styles.divider || 1;
    const linkStyle = section.styles.linkStyle || 1;
    const linkColor = section.styles.styles && section.styles.styles.link
      && section.styles.styles.link.active && section.styles.styles.link.color
      ? section.styles.styles.link
      : undefined;

    let fontSize;
    switch (themeData.typography.logo.fontSize) {

      case 'Small':
        fontSize = '30px';
        break;
      case 'Normal':
        fontSize = '38px';
        break;
      case 'Large':
        fontSize = '50px';
        break;
      default:
        fontSize = '38px';

    }

    this.state = {
      links: this.props.pageContext.navLinks,
      secondaryLinks: this.props.pageContext.secondaryLinks,
      burgerBreak,
      divider,
      linkStyle,
      recStyles: section.styles.styles,
      logo,
      sitename,
      themeData,
      overlay: section.styles.overlay,
      full: section.styles.full,
      fixed_top: section.styles.fixed_top,
      layout: section.styles.layout,
      infoRow: section.styles.infoRow,
      matches: null,
      linkColor,
      fontSize,
      header,
    };

    this.updateMatches = this.updateMatches.bind(this);

  }

  componentDidMount() {

    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

  }

  componentWillUnmount() {

    if (this.mediaQueryList) {

      this.mediaQueryList.removeListener(this.updateMatches);

    }

  }

  updateMatches() {

    this.setState({
      matches: this.mediaQueryList.matches,
    });

  }

  render() {

    let result = null;

    if (this.state.matches !== null) {

      const isScrolling = this.props.isScrolling
        && this.props.pageContext.navigation.styles.fixed_top;
      const { header, logo } = this.state;

      let [, , , color] = this.state.themeData.colors;

      if (this.state.recStyles.font.titleColor) color = this.state.recStyles.font.titleColor;
      if (color) {

        color = formColor(
          { solid: color },
          false,
          1,
          undefined,
          this.state.themeData.colors,
          header && header.type === 'invert',
        ).backgroundColor;

      }

      const titleStyle = {
        fontFamily: this.state.themeData.typography.logo.name !== ''
          ? this.state.themeData.typography.logo.name
          : this.state.themeData.typography.default.name,
        fontSize: this.state.fontSize,
        lineHeight: this.state.themeData.typography.logo.lineHeight
          ? this.state.themeData.typography.logo.lineHeight
          : this.state.themeData.typography.default.lineHeight,
        color,
        fontWeight: this.state.themeData.typography.logo.weight
          ? this.state.themeData.typography.logo.weight
          : 700,
        textTransform: this.state.themeData.typography.logo.textTransform
          ? this.state.themeData.typography.logo.textTransform
          : 'none',
        maxWidth: '100%',
      };

      let style;

      if (this.state.recStyles) {

        let { backgroundColor } = this.state.recStyles;
        let { opacity } = backgroundColor;
        if (
          header
          && header.type === 'alt'
          && header.pageBGColor
          && header.pageBGColor.active
          && header.pageBGColor.color
        ) {

          backgroundColor = { solid: header.pageBGColor.color };
          ({ opacity } = header.pageBGColor);

        } else if (
          this.state.recStyles.backgroundColor.solid === ''
          && this.state.recStyles.backgroundColor.gradient.from === ''
        ) {

          backgroundColor = { solid: '#FFFFFF' };

        }

        opacity = isScrolling === false ? opacity : 1;
        const bgColor = formColor(
          backgroundColor,
          false,
          opacity,
          undefined,
          this.state.themeData.colors,
        );

        let x;
        let y;
        let blur;
        let spread;
        let sActive = false;
        if (header && header.type === 'alt' && header.pageShadow && header.pageShadow.active) {

          ({
            x, y, blur, spread,
          } = header.pageShadow);
          sActive = true;

        } else if (this.state.recStyles.shadow.active) {

          ({
            x, y, blur, spread,
          } = this.state.recStyles.shadow);
          sActive = true;

        }

        let sColor;
        if (sActive) {

          let solid = header ? header.pageShadow.color : this.state.recStyles.shadow.color;
          const sOpacity = header ? header.pageShadow.opacity : this.state.recStyles.shadow.opacity;
          if (!solid && this.state.themeData) {

            [, , solid] = this.state.themeData.colors;

          } else if (!solid) {

            solid = '#000000';

          }

          sColor = formColor(
            { solid },
            false,
            sOpacity,
            undefined,
            this.state.themeData.colors,
            header && header.type === 'invert',
          );

        }

        const boxShadow = sActive
          ? `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}`
          : undefined;
        const WebkitFilter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
        const MozFilter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
        const OFilter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
        const msFilter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
        const filter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';

        style = {
          boxShadow,
          WebkitFilter,
          MozFilter,
          OFilter,
          msFilter,
          filter,
        };

        if (this.state.recStyles.backgroundColor.gradient.from !== '' && this.state.recStyles.backgroundColor.active) {

          style.background = bgColor.background;

        } else if (this.state.recStyles.backgroundColor.active
        || (header && header.type === 'alt' && header.pageBGColor && header.pageBGColor.active)) {

          style.backgroundColor = bgColor.backgroundColor;

        }

        let solid = '#000000';
        let fOpacity = 1;
        if (header && header.type === 'alt' && header.pageLink && header.pageLink.active) {

          solid = header.pageLink.color;
          fOpacity = header.pageLink.opacity;

        } else if (this.state.recStyles.font.fontColor.color !== '') {

          solid = this.state.recStyles.font.fontColor.color;
          fOpacity = this.state.recStyles.font.fontColor.opacity;

        }

        const fontColor = formColor(
          { solid },
          undefined,
          fOpacity,
          undefined,
          this.state.themeData.colors,
          header && header.type === 'invert',
        );

        style.color = fontColor.backgroundColor;

      }

      const burgerColor = formColor(
        { solid: this.state.recStyles.burgerColor.color },
        false,
        this.state.recStyles.burgerColor.opacity,
        undefined,
        this.state.themeData.colors,
        header && header.type === 'invert',
      );

      let infoRow;
      if (this.state.infoRow && this.state.infoRow.active) {

        const solid = this.state.infoRow.style.color || '#000000';
        const opacity = isScrolling === false
          ? this.state.infoRow.style.opacity : 1;
        const infoColor = formColor(
          { solid },
          undefined,
          opacity,
          undefined,
          this.state.themeData.colors,
          header && header.type === 'invert',
        );

        infoRow = (
          <div
            style={infoColor}
            className={`infoRow ${styles.infoRow}`}
          >
            { HTMLParser(`<span>${this.state.infoRow.data}</span>`) }
          </div>
        );

      }

      let secondaryNav;
      if (this.state.secondaryLinks && this.state.secondaryLinks.length > 0) {

        secondaryNav = (
          <NavigationLinks
            links={this.state.secondaryLinks}
            color={style.color}
            color0={this.state.themeData.colors[0]}
            themeData={this.state.themeData}
            buttons={this.props.pageContext.buttons}
            images={this.props.pageContext.images}
            secondary
            linkColor={this.state.linkColor}
            header={header}
          />
        );

      }

      let navbar;
      let logoStyle;
      let menu;
      let noLinks;
      if (this.state.layout === 'left') {

        navbar = 'navbar';
        logoStyle = 'logoLeft';
        menu = 'menuRight';

      } else if (this.state.layout === 'center') {

        logoStyle = 'logoCenter';
        navbar = 'navbarCenter';
        menu = 'menuCenter';

      } else if (this.state.layout === 'right') {

        navbar = 'navbarReverse';
        logoStyle = 'logoRight';
        menu = 'menuLeft';

      } else if (this.state.layout === 'divided') {

        if (
          (this.state.links && this.state.links.length > this.state.burgerBreak)
          || (!this.state.matches && this.state.links && this.state.links.length > 0)
        ) {

          navbar = 'navbar';
          menu = 'menuRight';
          logoStyle = 'logoDividedBurger';

        } else {

          navbar = `navbarDivided${secondaryNav ? 'Secondary' : ''}`;

          if (this.state.links && this.state.links.length === 0) {

            noLinks = 'navbarDividedNoLinks';

          }

          menu = 'menuDivided';
          logoStyle = 'logoDivided';

        }

      }

      let navStyle;
      if (
        this.state.fixed_top === false
        && this.state.overlay === false
      ) {

        navStyle = 'sectionNavbar';

      } else if (
        this.state.fixed_top === true
        && this.state.overlay === false
      ) {

        navStyle = 'sectionNavbarTop';

      } else if (
        this.state.fixed_top === true
        && this.state.overlay === true
      ) {

        navStyle = 'sectionNavbarTopOverlay';

      } else if (
        this.state.fixed_top === false
        && this.state.overlay === true
      ) {

        navStyle = 'sectionNavbarOverlay';

      }

      const navWrapper = this.state.full === true ? 'navbarFull' : 'navbarPartial';
      const layout = this.state.layout.charAt(0).toUpperCase() + this.state.layout.slice(1);
      const target = '/';
      const shrink = isScrolling && logo && logo.active && this.state.layout !== 'center';
      const linkStyle = shrink ? { lineHeight: 0 } : undefined;

      let logoId;
      let logoActive = false;
      if (header && header.type === 'alt' && header.pageLogo && header.pageLogo.active && header.pageLogo.logoId) {

        logoActive = true;
        ({ logoId } = header.pageLogo);

      } else if (logo && logo.active) {

        logoActive = true;
        logoId = logo.id;

      }

      const logoHeight = {
        height:
          logoActive && this.state.matches
            ? `${logo && logo.active && logo.logoHeight !== undefined ? logo.logoHeight : 60}px`
            : 'fit-content',
        maxHeight: logoActive
          ? `${logo && logo.active && logo.logoHeight !== undefined ? logo.logoHeight : 60}px`
          : undefined,
      };

      const lg = (
        <div className={styles[logoStyle]} style={logoHeight}>
          <Link
            to={target}
            className={styles.linkStyle}
            style={{ ...linkStyle, ...{ maxHeight: logoHeight.maxHeight } }}
          >
            {
              !logoActive
                ? (
                  <span style={titleStyle}>
                  { this.state.sitename }
                </span>
                )
                : (
                  <Image
                    id={logoId}
                    alt="Logo"
                    imageClass={`navbarLogo${shrink ? 'Scrolling' : ''}`}
                    images={this.props.pageContext.images}
                    style={{ maxHeight: logoHeight.maxHeight }}
                  />
                )
            }
          </Link>
        </div>
      );

      // Domain and preview params from FE not needed
      let nav;
      if (this.state.links && this.state.links.length > 0) {

        nav = (
          <NavigationLinks
            links={this.state.links}
            burgerBreak={this.state.burgerBreak}
            burgerColor={burgerColor}
            divider={this.state.divider}
            linkStyle={this.state.linkStyle}
            layout={layout}
            menuStyle={menu}
            lg={lg}
            color={style.color}
            color0={this.state.themeData.colors[0]}
            themeData={this.state.themeData}
            matches={this.state.matches}
            images={this.props.pageContext.images}
            secondaryNav={secondaryNav}
            buttons={this.props.pageContext.buttons}
            infoRow={infoRow !== undefined}
            linkColor={this.state.linkColor}
            header={header}
          />
        );

      }

      let combinedNavs;
      let topSecondary;
      if (!secondaryNav || !this.state.matches) combinedNavs = nav;
      else if (this.state.layout === 'center' || this.state.layout === 'divided') {

        combinedNavs = nav;
        let styleName = 'topSecondary';
        if (this.state.layout === 'divided' && this.state.links
            && this.state.links.length > this.state.burgerBreak) styleName = 'topSecondaryDividedBurger';
        topSecondary = (
            <div className={styles[styleName]}>
              { secondaryNav }
            </div>
        );

      } else if (this.state.layout === 'left' || this.state.layout === 'right') {

        combinedNavs = (
            <div className={styles.combinedNavs}>
              { nav }
              { secondaryNav }
            </div>
        );

      }

      result = (
        <div className={`${navStyle} ${styles[navStyle]}`} id="NavigationBarAnchor">
          { infoRow }
          <div
            className={styles[`navContainer${isScrolling && this.state.layout !== 'center' ? 'Small' : secondaryNav && (this.state.layout === 'center' || (this.state.layout === 'divided' && this.state.links && this.state.links.length < this.state.burgerBreak)) ? 'Secondary' : ''}`]}
          >
            <div style={style} className={styles.background} />
            <div className={styles[navWrapper]}>
              <div className={`${this.state.full ? '' : 'container'} ${styles[navbar]} ${noLinks !== undefined ? styles[noLinks] : ''}`}>
                { topSecondary }
                {
                  (
                    this.state.layout !== 'divided'
                    || (this.state.links && this.state.links.length === 0)
                    || (
                      this.state.layout === 'divided'
                      && (
                        (this.state.links && this.state.links.length > this.state.burgerBreak)
                        || !this.state.matches
                      )
                    )
                  )
                  && (
                    <React.Fragment>
                      { lg }
                    </React.Fragment>
                  )
                }
                { combinedNavs }
              </div>
            </div>
          </div>
        </div>
      );

    }

    return result;

  }

}

NavigationBar.propTypes = {
  site: PropTypes.shape({}),
};

export default NavigationBar;
