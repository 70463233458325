import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import { format, isValid } from 'date-fns/esm';

import SectionMedia from '../SectionMedia';

import styles from './styles.module.css';

class ArticleLoopLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const dataIndex = props.section.data.findIndex(elem => elem.type === 'DATA');

    let startIndex;
    let loopLength;
    let showExcerpt;
    let showThumbnails;
    let showLink;
    let onlyFeatured;
    let limitTags;
    let linkedTo;

    if (dataIndex > -1) {

      ({
        startIndex,
        loopLength,
        showExcerpt,
        showThumbnails,
        showLink,
        onlyFeatured,
        limitTags,
        linkedTo,
      } = props.section.data[dataIndex]);

    }

    let layout = 'side';
    if (props.section && props.section.styles) ({ layout } = props.section.styles);

    this.state = {
      showExcerpt,
      showThumbnails,
      showLink,
      onlyFeatured,
      startIndex,
      loopLength,
      layout,
      linkedTo,
      limitTags,
    };

    this.createLoopElement = this.createLoopElement.bind(this);
    this.createLoopImage = this.createLoopImage.bind(this);

  }

  createLoopImage(articleLink, image, index, imageStyle, imgWrapper, mediaWrapper) {

    const img = (
      <div key={`article_${this.props.section._id}_link_${index}`} className={styles[imgWrapper]}>
        {
          (this.state.showThumbnails && image.id)
          && (
            <Link to={articleLink}>
              <SectionMedia
                mediaType="IMAGE"
                wrapperStyle={mediaWrapper}
                elementStyle={imageStyle}
                id={image.id}
                alt={image.alt || ''}
                data={image}
                images={this.props.images}
                pagePathList={this.props.pagePathList}
                articlePathList={this.props.articlePathList}
                filePathList={this.props.filePathList}
              />
              {
                this.state.layout === 'hero'
                && (
                  <div className={styles.heroOverlay} />
                )
              }
            </Link>
          )
        }
      </div>
    );

    return img;

  }

  createLoopElement(
    index, featured, pubDate, title, excerpt,
    image, link, color3, color0, amount,
  ) {

    const validDate = isValid(new Date(pubDate));
    const displayDate = validDate ? format(new Date(pubDate), 'd.M.yyyy') : pubDate;
    let loopElement;

    let col;
    let outerWrapper;
    let imgWrapper;
    let frameWrapper;
    let loopWrapper;
    let infoWrapper;
    let linkWrapper;
    let textWrapper;
    let titleColor = color3;
    let imageStyle = 'articleLoopImage';
    let mediaWrapper = 'imageContent2';
    let color;
    let lnk;
    let shadow = {};

    const to = `${link.target}`;

    if (this.state.layout === 'side') {

      col = `col-12${amount >= 2 ? ' col-lg-6' : ''}${amount >= 3 ? ' col-xl-4' : ''}`;
      imgWrapper = featured === true ? 'imageWrapperFeatured' : 'imageWrapper1';
      frameWrapper = 'frameWrapper1';
      loopWrapper = featured === true ? 'articleLoopWrapperFeaturedLayout1' : 'articleLoopWrapper1';
      infoWrapper = 'infoWrapper1';
      textWrapper = 'textWrapper1';
      outerWrapper = 'articleLoopOuterWrapper1';
      color = { color: `${featured === true ? 'white' : 'black'}` };
      titleColor = featured === true ? { color: 'white' } : color3;

      lnk = (
        <div>
          <Link
            to={to}
            style={color}
          >
            { link.text }
          </Link>
        </div>
      );

    } else if (this.state.layout === 'below' || this.state.layout === 'hero') {

      col = 'col-12';
      textWrapper = 'textWrapper2';
      let border;
      let bg;
      let linkColor;

      if (this.state.layout === 'below') {

        imgWrapper = featured === true ? 'imageWrapperFeatured' : 'imageWrapper2';
        frameWrapper = 'frameWrapper2';
        loopWrapper = featured === true ? 'articleLoopWrapperFeatured' : 'articleLoopWrapper2';
        infoWrapper = featured === true ? 'infoWrapperFeatured' : 'infoWrapper2';
        border = featured === true ? 'none' : `2px solid ${color0}`;
        bg = featured === true ? color0 : 'transparent';
        color = { color: `${featured === true ? 'white' : 'black'}` };
        titleColor = featured === true ? { color: 'white' } : color3;
        imageStyle = featured === true ? 'articleLoopImage' : 'imageContent';
        mediaWrapper = featured === true ? 'imageContent2' : 'imageContent';
        outerWrapper = 'articleLoopOuterWrapper2';
        linkColor = color;

      } else {

        col = 'col-12';
        frameWrapper = 'frameWrapper3';
        loopWrapper = 'articleLoopWrapper3';
        infoWrapper = 'infoWrapper1';
        border = 'none';
        bg = '#fff';
        outerWrapper = 'articleLoopOuterWrapper3';
        color = { color: '#fff' };
        titleColor = { color: '#fff' };
        linkColor = { color: '#000' };
        shadow = { textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)' };

      }

      linkWrapper = {
        border,
        padding: '10px 14px',
        width: 'fit-content',
        backgroundColor: bg,
        cursor: 'pointer',
      };
      lnk = (
        <Link
          to={to}
          id={`${this.state.linkedTo}_ArticleLink_${index}`}
        >
          <button style={{ ...linkWrapper, ...linkColor }}>
            { link.text }
          </button>
        </Link>
      );

    }

    if (col !== undefined && col !== null) {

      loopElement = (
        <div key={`article_loop_${index}`} className={`${col}  ${styles[outerWrapper]}`}>
          <div className={styles[frameWrapper]}>
            {
              (this.state.showThumbnails && image.id && this.state.layout !== 'hero')
              && (this.createLoopImage(to, image, index, imageStyle, imgWrapper, mediaWrapper))
            }
            <div className={styles[loopWrapper]}>
              <div className={styles[infoWrapper]}>
                <div className={styles[textWrapper]}>
                  <span style={{ ...color, ...shadow }}>
                    { displayDate }
                  </span>
                </div>
                <div className={styles[textWrapper]}>
                  <Link to={to}>
                    <h3 style={{ ...titleColor, ...shadow }}>
                      {title}
                    </h3>
                  </Link>
                </div>
                {
                  this.state.showExcerpt
                  && (
                    <div className={styles[textWrapper]}>
                      <span className={styles.infoExcerpt} style={{ ...color, ...shadow }}>
                        { HTMLParser(excerpt) }
                      </span>
                    </div>
                  )
                }
                {
                  this.state.showLink
                  && (lnk)
                }
              </div>
            </div>
          </div>
        </div>
      );

    }

    return loopElement;

  }

  render() {

    const color3 = {
      color: this.props.themeData.colors[3],
    };

    const color0 = this.props.themeData.colors[0];

    const activeElements = [];
    if (this.props.articleMother
      && this.props.articleMother._id.toString() === this.state.linkedTo) {

      this.props.articleMother.articles.some((article, index) => {

        let tagLimit = true;
        if (this.state.limitTags && this.state.limitTags.active && this.state.limitTags.list.length > 0) {

          tagLimit = false;
          article.tags.some(tag => {

            let found = false;
            if (this.state.limitTags.list.includes(tag)) {

              found = true;
              tagLimit = true;

            }

            return found;

          });

        }

        if ((this.state.onlyFeatured === true ? article.featured === true : true) && tagLimit) {

          if (
            index >= Number(this.state.startIndex)
            && activeElements.length < Number(this.state.loopLength)
          ) activeElements.push(article);

        }

        return activeElements.length === Number(this.state.loopLength);

      });

    }

    const loopElementList = [];
    activeElements.forEach((article, index) => {

      const newLoopElement = this.createLoopElement(
        index, article.featured, article.pubDate, article.title, article.excerpt,
        article.image, article.link, color3, color0, activeElements.length,
      );

      let img;
      if (this.state.layout === 'hero') {

        img = this.createLoopImage(article.link.target, article.image, index, 'articleLoopImage', 'imageWrapper3', 'imageContent2');

      }

      loopElementList.push([newLoopElement, img]);

    });

    let loopElements;
    if (this.state.layout === 'hero') {

      loopElements = (
        <div>
          {
            loopElementList.map((element, i) => (

              <div key={`Article_${this.props.section._id}_hero_wrapper_${i}`} className={styles.articleHeroWrapper}>
                {element[1]}
                <div className="container">
                  <div className="row">
                    {element[0]}
                  </div>
                </div>
              </div>

            ))

          }
        </div>
      );

    } else {

      loopElements = (
        <div className="container">
          <div className="row row-eq-height">
            { loopElementList.map(element => element[0]) }
          </div>
        </div>
      );

    }

    return (
      <div className={styles.wrapper}>
        {
          this.props.section.data.map((elem, i) => {

            let result = null;
            if (elem.type === 'HEADINGS/HEADING-TWO' && elem.active) {

              result = (
                <div
                  key={`${this.props.section._id}_Title_${i}_section`}
                  className={`container ${styles.articleLoopMainHeader}`}
                >
                  { HTMLParser(`<h2>${elem.text}</h2>`) }
                </div>
              );

            } else if (elem.type === 'HEADINGS/HEADING-FOUR' && elem.active) {

              result = (
                <div
                  key={`${this.props.section._id}_Title_${i}_section`}
                  className={`container ${styles.articleLoopSubHeader}`}
                >
                  { HTMLParser(`<h4>${elem.text}</h4>`) }
                </div>
              );

            }

            return result;

          })
        }
        { loopElements }
      </div>
    );

  }

}

ArticleLoopLayout.propTypes = {
  startIndex: PropTypes.string,
  loopLength: PropTypes.string,
  showExcerpt: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  siteLanguage: PropTypes.string,
  articleMother: PropTypes.shape({
    articles: PropTypes.any,
  }),
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.shape({
      default: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      heading: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      navigation: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
    }),
  }),
};

export default ArticleLoopLayout;
