import invertHexColor from './helpers/invertColor';
import getColor from './helpers/getColor';

export const componentToHex = c => {

  const hex = parseInt(c, 10).toString(16).toUpperCase();
  return hex.length === 1 ? `0${hex}` : hex;

};

export const rgbToHex = (r, g, b) => (

  `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`

);

export const hexToRgb = (hex, a) => {

  // Expand shorthand form
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const expanded = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expanded);
  return result && a !== undefined
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a,
    }
    : result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : undefined;

};

export const formColor = (recStyles, active, a1, a2, colors, invert) => {

  const style = {};
  if (recStyles && recStyles.solid && recStyles.solid.startsWith('rgb')) {

    style.backgroundColor = recStyles.solid;
    return style;

  }
  const recSolid = getColor(recStyles, colors);

  if (
    recStyles !== undefined
    && !active
    && a1 === undefined
    && a2 === undefined
  ) {

    const bgColor = (
      recSolid || (recStyles.gradient ? recStyles.gradient.from : '')
    );
    const to = (
      recSolid
        ? undefined
        : recStyles.gradient
          ? recStyles.gradient.to : ''
    );

    const bgLinear = to ? `linear-gradient(to right, ${bgColor} , ${to})` : undefined;

    if (recSolid) style.backgroundColor = bgColor;
    else style.background = bgLinear;

  } else if (
    recStyles !== undefined
    && (active || a1 !== undefined || a2 !== undefined)
  ) {

    let rgba;
    let rgbaTo;

    if (recSolid && (recSolid.length === 7 || recSolid.length === 4)) {

      let color = recSolid;
      if (invert) color = invertHexColor(color);
      const rgbaRec = hexToRgb(color, a1);
      if (rgbaRec && a1 !== undefined) rgba = `rgba(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b},${rgbaRec.a})`;
      else if (rgbaRec) rgba = `rgb(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b})`;
      else rgba = color;

    } else if (
      recStyles.gradient && recStyles.gradient.from
      && (recStyles.gradient.from.length === 7 || recStyles.gradient.from.length === 4)
    ) {

      const colorFrom = recStyles.gradient.from;
      const colorTo = recStyles.gradient ? recStyles.gradient.to : '';
      const rgbaRec = hexToRgb(colorFrom, a1);
      const rgbaToRec = hexToRgb(colorTo, a2);

      if (a1 !== undefined && a2 === undefined) {

        rgba = `rgba(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b},${rgbaRec.a})`;
        rgbaTo = `rgba(${rgbaToRec.r},${rgbaToRec.g},${rgbaToRec.b})`;

      } else if (a1 === undefined && a2 !== undefined) {

        rgba = `rgba(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b})`;
        rgbaTo = `rgba(${rgbaToRec.r},${rgbaToRec.g},${rgbaToRec.b},${rgbaToRec.a})`;

      } else if (a1 !== undefined && a2 !== undefined) {

        rgba = `rgba(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b},${rgbaRec.a})`;
        rgbaTo = `rgba(${rgbaToRec.r},${rgbaToRec.g},${rgbaToRec.b},${rgbaToRec.a})`;

      } else {

        rgba = `rgb(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b})`;
        rgbaTo = `rgb(${rgbaToRec.r},${rgbaToRec.g},${rgbaToRec.b}})`;

      }

    }

    const bgColor = rgba;
    const to = rgbaTo;

    const bgLinear = to ? `linear-gradient(to right, ${bgColor} , ${to})` : undefined;

    if (recStyles.solid !== '') style.backgroundColor = bgColor;
    else style.background = bgLinear;

  }

  return style;

};

export const constructLink = (l, pageIndex, articleIndex, fileIndex) => {

  let link = l;
  let type = 'EXTERNAL';

  if (typeof link === 'string') {

    if (link && link.startsWith('/')) type = 'PAGE';
    else if (link && link.startsWith('#')) type = 'SECTION';

  } else if (link) {

    ({ type } = link);
    if ((link.type === 'PAGE' || link.type === 'SECTION') && link.linkData && pageIndex && link.linkData.pageId in pageIndex) {

      const page = pageIndex[link.linkData.pageId];
      if (l.type === 'SECTION') link = `${page}#${l.linkData.sectionId}`;
      else link = page;

    } else if (link.type === 'EXTERNAL' && link.linkData) {

      ({ link } = link.linkData);

    } else if (link.type === 'FILE' && link.linkData && fileIndex && link.linkData.fileId && link.linkData.fileId in fileIndex) {

      link = `${process.env.IMAGES_CDN}/${fileIndex[link.linkData.fileId]}`;

    } else if (link.type === 'ARTICLE' && link.linkData && articleIndex && link.linkData.articleId in articleIndex) {

      link = articleIndex[link.linkData.articleId];

    } else link = '';

  }

  return { link, type };

};

export const createPreviewPath = image => {

  let start = image.path;
  const sIndex = image.path.lastIndexOf('.');
  let ext = '';
  if ((sIndex > 0) && (sIndex > image.path.lastIndexOf('/'))) {

    start = image.path.substring(0, sIndex);
    ext = image.path.substring(sIndex);

  }

  const size = image.sizes.includes('la') ? '-la' : `-${image.sizes[image.sizes.length - 1]}`;
  const src = encodeURI(`${process.env.IMAGES_CDN}/${start}${size}${ext}`)
    .replace('+', '%2B');

  return src;

};

export const getEmbedUrl = source => {

  let videoSource;
  let videoID;
  let url;
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = source.videoID.match(regExp);
  if (match && match[7].length === 11) {

    videoID = match[7];
    videoSource = 'youtube';

  } else {

    const vimeoRegExp = /^.*(?:vimeo.com)\/(?:channels\/|channels\/\w+\/|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape
    const vimeoMatch = source.videoID.match(vimeoRegExp);

    if (vimeoMatch && vimeoMatch[1]) {

      [, videoID] = vimeoMatch;
      videoSource = 'vimeo';

    } else videoID = undefined;

  }

  if (videoID) {

    let timeCode = Number(source.minutes * 60);
    timeCode += Number(source.seconds);

    if (videoSource === 'youtube') url = `https://www.youtube.com/embed/${videoID}${source.enableTimeCode ? `?start=${timeCode}` : ''}`;
    else if (videoSource === 'vimeo') url = `https://player.vimeo.com/video/${videoID}?responsive=true&transparent=0${source.enableTimeCode ? `#t=${source.minutes}m${source.seconds}s` : ''}`;

  }

  return url;

};
